<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Por favor espere
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>


        <v-card>
          <v-subheader>Plantillas de datos</v-subheader>
          <v-data-table
            :headers="headers"
            :items="DataTemplates"
            sort-by="name_table"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  >Listado de plantillas de datos</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialogDataTemplate"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="dialogCreate"
                    >
                      Nueva plantilla
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialogDataTemplate = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Plantilla de datos</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="sendTable(dataTemplate.id)"> Guardar </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-stepper v-model="stepperDataTemplate" vertical>
                      <v-stepper-step
                        :complete="stepperDataTemplate > 1"
                        step="1"
                        editable
                      >
                        Información plantilla
                      </v-stepper-step>

                      <v-stepper-content step="1">
                        
                          <v-form style="overflow-y: scroll">
                            <v-container>
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field
                                    label="Nombre plantilla"
                                    required
                                    v-model="dataTemplate.name_table"
                                    id="nombre-planilla"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    :items="subCategorys"
                                    label="Elegir Secretaría"
                                    id="sub-categoria"
                                    v-model="dataTemplate.sub_category_product_id"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <label>Meta de cuatrienio</label>
                                  <v-text-field
                                    label="Cuatrienio"
                                    required
                                    v-model="dataTemplate.cuatrienio"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <label>Nivel de avance</label>
                                  <v-radio-group
                                    row
                                    v-model="dataTemplate.advance"
                                  >
                                    <v-radio
                                      label="Bajo"
                                      color="red"
                                      value="1"
                                    ></v-radio>
                                    <v-radio
                                      label="Medio"
                                      color="orange"
                                      value="2"
                                    ></v-radio>
                                    <v-radio
                                      label="Alto"
                                      color="success"
                                      value="3"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <label>Unidad de medida</label>
                                  <v-select
                                    :items="medidas"
                                    label="Seleccionar"
                                    v-model="dataTemplate.measurement"
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <label>Linea base</label>
                                  <v-text-field
                                    label="Indique el número"
                                    required
                                    v-model="dataTemplate.baseline"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <quill-editor
                                    ref="myQuillEditor"
                                    v-model="dataTemplate.description"
                                    :options="editorOption"
                                /> 
                                
                              </v-row>
                            </v-container>
                          </v-form>
                        
                      </v-stepper-content>

                      <v-stepper-step
                        :complete="stepperDataTemplate > 3"
                        step="2"
                        editable
                      >
                        Carga de información
                      </v-stepper-step>

                      <v-stepper-content step="2">
                        <v-card
                          color="grey lighten-1"
                          class="mb-12"
                          height="60px"
                        >
                          <div class="text-center">
                            <v-btn
                              style="height: 45px"
                              class="ma-2"
                              color="secondary"
                              @click="$refs.fileInput.click()"
                            >
                              Seleccionar archivo importador de tablas
                            </v-btn>
                            <input
                              type="file"
                              :accept="SheetJSFT"
                              ref="fileInput"
                              @change="inputChange"
                            />
                          </div>
                        </v-card>
                      </v-stepper-content>

                      <v-stepper-step step="3" editable>
                        Gráficos
                      </v-stepper-step>
                      <v-stepper-content step="3">
                        <v-card
                          color="grey lighten-1"
                          class="mb-12"
                          height="45px"
                        >
                          <v-select
                            :items="Types"
                            label="Tipo de graficas"
                            v-model="dataTemplate.graph_type_id"
                          ></v-select>
                        </v-card>
                      </v-stepper-content>
                      <v-stepper-step step="4" editable>
                        Visualización de información
                      </v-stepper-step>
                      <v-stepper-content step="4">
                        <v-card color="grey lighten-1" class="mb-12">
                          <div v-if="dataTemplate.graph_type_id == 1">
                            <h3>Grafica lineal</h3>
                            <line-chart
                              :data="chartData"
                              :messages="{ empty: 'No data' }"
                            ></line-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 2">
                            <h3>Grafica en columnas</h3>
                            <column-chart
                              :data="chartData"
                              :messages="{ empty: 'No data' }"
                            ></column-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 3">
                            <h3>Grafica de Pie</h3>
                            <pie-chart
                              :data="chartData"
                              :messages="{ empty: 'No data' }"
                            ></pie-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 4">
                            <h3>Grafica tipo barras horizontales</h3>
                            <bar-chart
                              :data="chartData"
                              :messages="{ empty: 'No data' }"
                            ></bar-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 5">
                            <h3>Grafica area bajo la linea</h3>
                            <area-chart
                              :data="chartData"
                              :messages="{ empty: 'No data' }"
                            ></area-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 9">
                            <h3>Grafica Dispersion</h3>
                            <scatter-chart
                              :data="chartData"
                              :messages="{ empty: 'No data' }"
                            ></scatter-chart>
                          </div>

                          <div v-if="dataTemplate.graph_type_id == 6">
                            <h3>Grafica tipo barras horizontales</h3>
                            <bar-chart
                              :data="barsData"
                              :messages="{ empty: 'No data' }"
                            ></bar-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 7">
                            <h3>Grafica area bajo la linea</h3>
                            <area-chart
                              :data="barsData"
                              :messages="{ empty: 'No data' }"
                            ></area-chart>
                          </div>
                          <div v-if="dataTemplate.graph_type_id == 8">
                            <h3>Grafica Dispersion</h3>
                            <scatter-chart
                              :data="barsData"
                              :messages="{ empty: 'No data' }"
                            ></scatter-chart>
                          </div>
                          <div
                            v-if="dataTemplate.graph_type_id == 10"
                            class="speedometer__container"
                          >
                            <div class="speedometer__container--gauge">
                              <div class="speedometer__container--gauge__title">
                                <p>{{ dataTemplate.name_table }}</p>
                              </div>
                              <VueSvgGauge
                                :start-angle="-115"
                                :end-angle="115"
                                :value="value"
                                :separator-step="1"
                                :min="0"
                                :max="quantity"
                                :gauge-color="[
                                  { offset: 0, color: 'red' },
                                  { offset: valueMid, color: 'yellow' },
                                  { offset: 100, color: 'green' },
                                ]"
                                :scale-interval="0.8"
                              />
                              <div
                                class="speedometer__container--gauge__details"
                              >
                                <div
                                  class="speedometer__container--details_high"
                                >
                                  <p>{{ highValueText }}</p>
                                  <div class="square green"></div>
                                </div>
                                <!-- <div class="speedometer__container--details_high">
                                                            <p>Medio</p><div class="square yellow"></div>
                                                            </div> -->
                                <div
                                  class="speedometer__container--details_high"
                                >
                                  <p>{{ lowValueText }}</p>
                                  <div class="square red"></div>
                                </div>
                                <div
                                  class="speedometer__container--details_high"
                                >
                                  <p>{{ labelResult }}</p>
                                  <p>{{ result }}</p>
                                </div>
                              </div>

                              <div class="speedometer__container--description">
                                <div
                                  class="
                                    speedometer__container--description__principal
                                  "
                                >
                                  <p>{{ grayText }}</p>
                                </div>
                                <div
                                  class="
                                    speedometer__container--description__principal--price
                                  "
                                >
                                  <p>{{ greenText }}</p>
                                </div>
                                <div
                                  class="
                                    speedometer__container--description__invest
                                  "
                                >
                                  <p
                                    class="
                                      speedometer__container--description__invest--title
                                    "
                                  >
                                    {{ labelOne }}:
                                  </p>
                                  <p>{{ valueOne }}</p>
                                </div>
                                <div
                                  class="
                                    speedometer__container--description__future--invest
                                  "
                                >
                                  <p
                                    class="
                                      speedometer__container--description__invest--title
                                    "
                                  >
                                    {{ labelTwo }}:
                                  </p>
                                  <p>{{ valueTwo }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="deleteGraph(item)">
                mdi-delete
              </v-icon>
              <v-icon 
                small class="mr-2"
                @click="dialogEdit(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            
            <template v-slot:no-data> No se encontraron registros </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX, { read } from "xlsx";
import JSZip, { files } from "jszip";
import { Bar } from "vue-chartjs";

const _SheetJSFT = ["zip"].map((x) => "." + x).join(",");
export default {
  data() {
    return {
      value: "",
      result: "",
      valueLow: "",
      valueMid: "",
      valueHigh: "",
      quantity: "10",
      finalprueba: [],
      dimensions: [],
      architectures: [],
      chartData: {},
      barsData: {},
      dataExcel: [],
      dataSend: [],
      loader: null,
      loading: false,
      btnDisable: true,
      SheetJSFT: _SheetJSFT,
      zip: null,
      xlsx: null,
      tables: [],
      dialogDataTemplate: false,
      dataTemplate: {
        id: 0,
        name_table: "",
        client_id: "",
        data_table: [],
        graph_type_id: "",
        fields: [],
        sub_category_product_id: "",
        description: "",
        cuatrienio: "",
        advance: "",
        measurement: "",
        baseline: ""
      },
      headers: [
        {
          text: "Nombre plantilla",
          align: "start",
          value: "name_table",
        },
        { text: "Cliente", value: "client" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      DataTemplates: [],
      stepperDataTemplate: 1,
      dataType: {
        id: 0,
        name_field: "",
        type_field: "",
      },
      dataTypes: [
        {
          text: "Entero",
          value: "int",
        },
        {
          text: "Real",
          value: "float",
        },
        {
          text: "Cadena",
          value: "string",
        },
        {
          text: "Texto",
          value: "text",
        },
        {
          text: "Fecha",
          value: "date",
        },
      ],
      Fields: [
        {
          name_field: "Nombre",
          type_field: "string",
          orden: 1,
        },
        {
          name_field: "Edad",
          type_field: "int",
          orden: 2,
        },
        {
          name_field: "Ciudad",
          type_field: "string",
          orden: 3,
        },
      ],
      Clients: [],
      Types: [],
      highValueText: "",
      lowValueText: "",
      labelResult: "",
      grayText: "",
      greenText: "",
      labelOne: "",
      valueOne: "",
      labelTwo: "",
      valueTwo: "",
      totalValue: "",
      id : this.$route.params.id,

      subCategorys: [],
      actionMethod: 'create',
      dialog: false,
      medidas: [
        'KM',
        'M2',
        '#',
        '%'
      ],
      editorOption: {
        placeholder: 'Ingrese una descripción...',
      },
    };
  },
  methods: {
    watch: {
      dialog (val) {
        if (!val) return

        setTimeout(() => (this.dialog = false))
      },
    },
    getPercentByKpi() {
      this.value = (this.result / this.totalValue) * 10;
    },
    deleteGraph(id){
      this.$http
        .delete(
          "product_tables/"+id.id,
          { headers: { Authorization: "Bearer " + this.getCrypto() } }
        )
        .then((response) => {
          this.$router.go(-2)
        });

    },
    editGraph(id){

    },

    sendTable() {
      const architecture_tag_id = this.id;
      const graph_type_id = this.dataTemplate.graph_type_id;
      const name_table = this.dataTemplate.name_table;
      const data = JSON.stringify(this.dataExcel);
      const sub_category_product_id = this.dataTemplate.sub_category_product_id;
      const description = this.dataTemplate.description;
      const cuatrienio = this.dataTemplate.cuatrienio;
      const advance = this.dataTemplate.advance;
      const measure = this.dataTemplate.measurement;
      const baseline = this.dataTemplate.baseline;

      // Definición de parametros para envio en la petición 
      const attributes = 
      {
        product_id: architecture_tag_id,
        graph_type_id: graph_type_id,
        name: name_table,
        data: data,
        sub_category_product_id: sub_category_product_id,
        description: description,
        cuatrienio: cuatrienio,
        baseline: baseline,
        traffic_light: advance,
        measurement: measure
      }


      // Validación del tipo de metodo
      if(this.actionMethod == "update"){
        this.$http
        .put(`product_tables/${this.dataTemplate.id}`,{
          data: {
            attributes
          },
        },
          { headers: { Authorization: "Bearer " + this.getCrypto() } }
        )
        .then((response) => {
          console.log(response)
        });
      }else{
        this.$http
        .post("product_tables",{
          data: {
            attributes
          },
        },
          { headers: { Authorization: "Bearer " + this.getCrypto() } }
        )
        .then((response) => {
          console.log(response)
        });
      }

      this.dialogDataTemplate = false;
      this.$router.go(-2)
    },
    async inputChange(evt) {
      const files = evt.target.files;
      //console.log(files[0].name.substr(-4,4))
      if (files[0].name.substr(-4, 4) == ".zip") {
        if (files && files[0]) {
          JSZip.loadAsync(files[0]).then(
            (zip) => {
              this.zip = zip;

              zip.forEach(async (relativePath, zipEntry) => {
                if (
                  zipEntry.name.split(".")[1] &&
                  zipEntry.name.split(".")[1] === "xlsx"
                ) {
                  const file = await zip
                    .file(zipEntry.name)
                    .async("arraybuffer");
                  const wb = XLSX.read(file, { type: "buffer" });
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  this.dataExcel = data;
                  console.log(this.dataExcel, 'data excel')
                  if (this.dataExcel != null) {
                    this.convertJson(this.dataExcel);
                  }
                }
              });
            },
            (e) => {
              alert("Error reading " + ": " + e.message);
            }
          );
        }
      }

      if (files[0].name.substr(-4, 4) == "xlsx") {
        this.handleFileLoad(evt);
      }
    },
    handleFileLoad(e) {
      if (e.target.files.length > 0) {
        try {
          const file = e.target.files.async("arraybuffer");
          const wb = XLSX.read(file, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          this.dataExcel = data;
        } catch (exception) {}
      } else {
        toast("No files found", { type: "error" });
      }
    },
    convertJson(x) {
      console.log(x, 'este es X')
      const info = [];
      const twoInfo = [];
      for (var i = 0; i < x.length - 1; i++) {
        info.push(x[i + 1]);
      }

      this.valueLow = info[0][1];
      this.valueMid = 50;
      this.valueHigh = info[0][3];
      this.highValueText = info[0][2];
      this.lowValueText = info[0][0];
      this.labelResult = info[0][5];
      this.result = info[0][6];
      (this.grayText = info[0][7]),
        (this.greenText = info[0][8]),
        (this.labelOne = info[0][9]),
        (this.valueOne = info[0][10]),
        (this.labelTwo = info[0][11]),
        (this.valueTwo = info[0][12]);
      this.totalValue = info[0][4];
      this.getPercentByKpi();
      const y = x;
      for (var i = 0; i < x.length - 1; i++) {
        var r = [y[i + 1][0], y[i + 1][2]];
        twoInfo.push(r);
      }
      this.chartData = info;
      this.barsData = [
        { name: x[0][1], data: info },
        { name: x[0][2], data: twoInfo },
      ];
      const newJson = JSON.stringify(x);
    },

    quitarAcentos(cadena) {
      const acentos = {
        á: "a",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
      };
      return cadena
        .split("")
        .map((letra) => acentos[letra] || letra)
        .join("")
        .toString()
        .toLowerCase();
    },
    mounted() {
      this.renderChart(data, options);
    },
    // newTemplate() {},
    // editTemplate() {},
    getData() {},
    addDataType() {
      this.dataTemplate.fields.push({
        id: 0,
        name_field: this.dataType.name_field,
        type_field: this.dataType.type_field,
        orden: 1,
      });

      this.dataType = {
        id: 0,
        name_field: "",
        type_field: "",
      };
    },
    getDataType(type) {
      var typeData = "";
      switch (type) {
        case "string":
          typeData = "Cadena";
          break;
        case "int":
          typeData = "Entero";
          break;
      }
      return typeData;
    },

    async getCategories() {
      try {
        const segment = await this.$http.get("product_tables/product/" + this.id);
        this.finalprueba = segment.data
        console.log("final", this.finalprueba);
        if (segment.data.length > 0) {
          for (var i = 0; i < segment.data.length; i++) {
            const obj = {
              name_table: segment.data[i].name,
              client: this.getClientSelect().name,
              id: segment.data[i].id,
            };
            console.log('el obj',obj)
            this.DataTemplates.push(obj);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTypes() {
      this.$http.get("graph_types").then((response) => {
        const types = response.body;
        for (var i = 0; i < types.length; i++) {
          const text = types[i].type_graph;
          const value = types[i].id;
          const obj = {
            text: text,
            value: value,
          };
          this.Types.push(obj);
        }
      });
    },

    // Obtener Secretarias
    getSubCategorys() {
      this.$http
      .get("sub_category_products")
      .then((response) => {
          console.log('Segmentos consultados: ', response.body)
          this.subCategorys = response.body.map(item => ({
            text: item.name,
            value: item.id
          }))
      })
      .catch((error) => {
        console.log('Error al consultar las secretarías: ', error)
      });
    },


    // Abrir modal para crear template
    dialogCreate(){
      // Tipo de acción 
      this.actionMethod = 'create';
      // Se limpia la información
      this.dataTemplate = [];
      // Apertura del modal
      this.dialogDataTemplate = true;
    },

    // Abrir modal para editar template
    async dialogEdit(item) {
      try {
        // Mostrar preloader
        this.dialog = true;
        // Tipo de acción 
        this.actionMethod = 'update';
        // Consultamos la información del product_table seleccionado
        const productTable = await this.$http.get("product_tables/"+item.id);
        const data = productTable.body;

        // Ocultar preloader
        this.dialog = false;

        // Apertura del modal
        this.dialogDataTemplate = true;
        // Seteamos la información que se mostrará por defecto
        this.dataTemplate = {
          id: data.id,
          name_table: data.name,
          client_id: "", //this.getClient().id
          data_table: JSON.parse(productTable.body.data),
          graph_type_id: data.graph_type_id,
          fields: [],
          sub_category_product_id: data.sub_category_product_id,
          description: data.description,
          cuatrienio: data.cuatrienio,
          baseline: data.baseline,
          advance: data.traffic_light,
          measurement: data.measurement
        };


        this.dataExcel = JSON.parse(productTable.body.data);

        this.convertJson(JSON.parse(productTable.body.data))

        console.log(data, 'Esta es la data')

      } catch (error) {
        console.log('Error al consultar la información (product_tables)', error)
      }
    }

  },
  beforeMount() {
    this.getCategories();
    this.getTypes();
    this.getSubCategorys();
  },
};
</script>
<style scoped>
.speedometer__container {
  width: 820px;
  height: 240px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid rgb(2, 1, 1);
}
.speedometer__container--title {
  width: 100%;
}
.speedometer__container--title h2 {
  text-align: left;
}
.speedometer__container--gauge {
  width: 100%;
  height: 240px;
  display: grid;
  grid-template-columns: 2fr 2fr 4fr;
  grid-template-rows: 1fr 3fr;
}
.speedometer__container--details_high {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.square {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.green {
  background-color: rgba(0, 128, 21, 0.6);
}
.red {
  background-color: rgba(255, 0, 0, 0.6);
}
.yellow {
  background-color: rgba(255, 255, 0, 0.6);
}
.gauge {
  margin-left: 30px;
  width: 150px;
  height: 150px;
  grid-row: 2;
}
.speedometer__container--gauge__details {
  font-size: 10px;
  margin: 0 20px 0 40px;
  grid-row: 2;
  width: auto;
  height: auto;
}
.speedometer__container--description {
  margin: 30px 30px 30px 30px;
  display: flex;
  flex-wrap: wrap;
  width: 356px;
  grid-column: 3/4;
  grid-row: 1/3;
}

.speedometer__container--description__principal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 356px;
  background: #d8d8d8;
}
.speedometer__container--description__principal p {
  text-align: center;
  padding: 0;
  margin: 0;
}
.speedometer__container--description__principal--price {
  margin-top: 3px;
  height: 38px;
  width: 356px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #69c061;
  border-radius: 0px 0px 20px 20px;
}
.speedometer__container--description__principal--price p {
  text-align: center;
  padding: 0;
  margin: 0;
}
.speedometer__container--description__invest {
  margin: 0 auto;
  margin-top: 24px;
  height: 34px;
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
}
.speedometer__container--description__invest p {
  padding: 0;
  margin: 0;
}
.speedometer__container--description__future--invest {
  margin: 0 auto;
  margin-top: 6px;
  height: 34px;
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
}
.speedometer__container--description__future--invest p {
  padding: 0;
  margin: 0;
}
.speedometer__container--gauge__title {
  margin: 20px 0 0 30px;
}
.speedometer__container--gauge__title p {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}
.speedometer__container--description__invest--title {
  font-weight: bold;
}
</style>
